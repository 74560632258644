import { AppBar, Box, Card, CardContent, Grid, makeStyles, Tab, Tabs, Typography, withStyles } from "@material-ui/core";
import React, { useState } from "react";
import ToDoContainer from "../../components/auth/todo/ToDoContainer";
import { lessons } from "../../data";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: '80vh',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));
const StyledTab = withStyles(theme => ({
  root: {
    flex:1,
    height:100,
    color: "white",
    fontWeight: 500,
    // marginLeft:1,
    // backgroundColor:'green',
  }
}))(props => 
  <Card style={{display:'flex',heigth:100,margin:15,justifySelf:'center',alignItems:'center',backgroundColor:props.color}}>             
    <Tab disableRipple {...props} />
  </Card> 
);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
function LessonTaskTodoPage() {
  const classes = useStyles();
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    console.log({newValue})
    setValue(newValue);
  };
  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  return (
    <>
      <Grid xs={12} md={12} style={{ display: "flex" }}>
        <Grid xs={3}>
          <h6>
            <span style={{ paddingLeft: 15 }}>Lessons</span>
          </h6>
        </Grid>
        <Grid xs={3}>
          <h6>
            <span style={{ paddingLeft: 15 }}>To Do Tasks</span>
          </h6>
        </Grid>
        <Grid xs={3}>
          <h6>
            <span style={{ paddingLeft: 15 }}>Inprogress tasks</span>
          </h6>
        </Grid>
        <Grid xs={3}>
          <h6>
            <span style={{ paddingLeft: 15 }}>Completed task</span>
          </h6>
        </Grid>
      </Grid>
      <Grid xs={12} style={{ display: "flex" }}>
        <Grid xs={3} className={classes.root}>
          <AppBar position="relative" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
              orientation="vertical"
              indicatorColor="primary"
              variant="scrollable"
              className={classes.tabs}
            >
              <StyledTab label={'lesson'} {...a11yProps(0)} style={{border:1}} color={'red'}/>
              {
                lessons.map((lesson) => (
              <StyledTab label={lesson.name} {...a11yProps(lesson.id)} style={{border:1}} color={lesson.color}/>
              ))}
              {
                lessons.map((lesson) => (
              <StyledTab label={lesson.name} {...a11yProps(lesson.id)} style={{border:1}} color={lesson.color}/>
              ))}
            </Tabs>
          </AppBar>
        </Grid>
        <Grid xs={9}>
        <TabPanel
              value={value}
              index={0}
              // style={{ backgroundColor: "lightblue" }}
              key={0}
            >
               <Box display="flex" justifyContent="center" alignItems='center' p={1} bgcolor="background.paper" height={'80vh'}>
                 <Box flexDirection='column'>
        <Box p={1} bgcolor="grey.300">
          Student Name
        </Box>
        <Box p={1} bgcolor="grey.300">
          Course Name
        </Box>
        <Box p={1} bgcolor="grey.300">
          Mentor Name
        </Box>
        </Box>
      </Box>
            </TabPanel>
          {lessons.map((lesson) => (
            <TabPanel
              value={value}
              index={lesson.id}
              style={{ backgroundColor: "lightblue",position:'absolute',overflowY:'scroll', height:'80vh'}}
              key={lesson.id}
            >
              <ToDoContainer lessonTasks={lesson.tasks} />
            </TabPanel>
          ))}
          {console.log({ value })}
        </Grid>
      </Grid>
    </>
  );
}
export default LessonTaskTodoPage;
