import { Button } from '@material-ui/core';
import React, { Component } from 'react'
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import { CLIENT_ID } from '../../data';



class GoogleBtn extends Component {
   constructor(props) {
    super(props);

    this.state = {
      isLogined: false,
      accessToken: ''
    };

    this.login = this.login.bind(this);
    this.handleLoginFailure = this.handleLoginFailure.bind(this);
    this.logout = this.logout.bind(this);
    this.handleLogoutFailure = this.handleLogoutFailure.bind(this);
  }

  login (response) {
    if(response.accessToken){
      this.setState(state => ({
        isLogined: true,
        accessToken: response.accessToken
      }));
    }
  }

  logout (response) {
    this.setState(state => ({
      isLogined: false,
      accessToken: ''
    }));
  }

  handleLoginFailure (response) {
    alert('Failed to log in')
  }

  handleLogoutFailure (response) {
    alert('Failed to log out')
  }

  render() {
    return (
    <>
      { this.state.isLogined ?
        <GoogleLogout
          clientId={ CLIENT_ID }
          buttonText='Logout'
          onLogoutSuccess={()=> this.logout }
          onFailure={()=> this.handleLogoutFailure }
        />
        : <GoogleLogin
          clientId={ CLIENT_ID }
          buttonText='Login'
          onSuccess={()=> this.login }
          onFailure={()=> this.handleLoginFailure }
          cookiePolicy={ 'single_host_origin' }
          responseType='code,token'
          style={{width:300,padding:10}}
          render={renderProps => (
            <Button style={{border:3,borderColor:'black',borderWidth:1}} onClick={renderProps.onClick} disabled={renderProps.disabled}>Signin with Google</Button>
          )}
        />
      }
      { this.state.accessToken ? console.log(this.state.accessToken) : null }

    </>
    )
  }
}

export default GoogleBtn;