import React from 'react';
import ReactDOM from 'react-dom';
import { Router,Route, Switch, Redirect } from "react-router-dom";
import { PrivateRoute } from './redux/_helpers/PrivateRoute';
import AuthLayout from './auth_layouts/_index';
import LandingLayout from "./landing_layouts/_index";
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './redux/_helpers/store';
import { history } from './redux/_helpers/history';
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={history}>
        <Switch>
          <PrivateRoute path="/auth" component={AuthLayout} />
          <Route path ="/main"component={LandingLayout} />
          {localStorage.getItem('user')?<Redirect from="/" to="/auth"/>:<Redirect from="/" to="/main/login"/>}
        </Switch>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
reportWebVitals();
