import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {dashboardRoutes} from "../routes";
const ContentComponent = ({color})=>{
    return(
    <Switch>
      {dashboardRoutes.map((prop, key) => {
        if (prop.layout === "/auth") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={()=><prop.component color={color}/>}
              key={key}
            />
          );
        }
        return null;
      })}
      <Redirect from="/auth" to="/auth/mentors" />
    </Switch>
    )};
  export default ContentComponent;