import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {landingRoutes} from "../routes";
const ContentComponent = ()=>{
    return(
    <Switch>
      {landingRoutes.map((prop, key) => {
        if (prop.layout === "/main") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
        return null;
      })}
      <Redirect from="/main" to="/main/login" />
    </Switch>
    )};
  export default ContentComponent;