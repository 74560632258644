import axios from 'axios';
import { ACCESS_TOKEN, API_URL } from '../_helpers/config';

function addParamsToURL(url, params) {
    if (params) {
        let temp = API_URL+url;
        temp = temp + '/' + params;
        return temp;
    }
    return url;
}

const getHeaders = (token) => {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
    }
    return token != null ? {
        headers:{...headers,Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`}
    }  : { headers }
};

export const api = (method, endpoint, token, body, params) => {
    switch (method) {
        case 'GET':
            // HTTP GET Request - Returns Resolved or Rejected Promise
            return new Promise((resolve, reject) => {
                const URL = addParamsToURL(`${endpoint}`, params);
                console.log(URL);
                axios
                    .get(URL, getHeaders(token))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        case 'POST':
            // HTTP POST Request - Returns Resolved or Rejected Promise
            return new Promise((resolve, reject) => {
                const URL = addParamsToURL(`${endpoint}`, params);
                console.log(URL);
                axios
                    .post(URL, body, getHeaders(token))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        console.log(error);
                        if (error.response) {
                            if (error.response.data.statusCode === 400) {
                                reject(error.response.data);
                            } else {
                                console.log('error hit');
                                // Errorpage(500, 'Sorry, something went wrong.');
                            }
                        }
                    });
            });
        case 'DELETE':
            // HTTP DELETE Request - Returns Resolved or Rejected Promise
            return new Promise((resolve, reject) => {
                const URL = addParamsToURL(`${endpoint}`, params);
                axios
                    .delete(URL, getHeaders(token))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        // History.push(`/servererror/${403}`)
                        console.log(error);
                        reject(error);
                    });
            });
        case 'PUT':
            // HTTP PUT Request - Returns Resolved or Rejected Promise
            return new Promise((resolve, reject) => {
                const URL = addParamsToURL(`${endpoint}`, params);
                axios
                    .put(URL, body, getHeaders(token))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    });
            });

        default:
            return null;
    }
};
