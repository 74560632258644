import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
// import DashboardPage from "./views/auth_view/Dashboard";
import LoginPage from "./views/landing_view/LoginPage";
import WelcomePage from "./views/landing_view/WelcomePage";
import CoursePage from "./views/auth_view/CoursePage";
import MentorPage from "./views/auth_view/MentorPage";
import TaskTodoPage from "./views/auth_view/TaskTodoPage";
import RegisterPage from "./views/landing_view/RegisterPage";
import ProfilePage from "./views/auth_view/ProfilePage";
import EnrollClassPage from "./views/auth_view/EnrollClassPage";
import TaskPage from "./views/auth_view/TaskPage";


export const dashboardRoutes =[
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   icon: Dashboard,
  //   component: DashboardPage,
  //   layout: "/auth"
  // },
  {
    path: "/mentors",
    name: "Mentors",
    icon: Person,
    component: MentorPage,
    layout: "/auth"
  },
  {
    path: "/courses",
    name: "Courses",
    icon: Dashboard,
    component: CoursePage,
    layout: "/auth"
  },
  {
    path: "/lessontodo",
    name: "Tasks todo",
    icon: Dashboard,
    component: TaskTodoPage,
    layout: "/auth"
  }, 
  {
    path: "/enroll-class-room",
    name: "Enroll class room",
    icon: Dashboard,
    component: EnrollClassPage,
    layout: "/auth"
  },
  {
    path: "/user",
    name: "User profile",
    icon: Person,
    component: ProfilePage,
    layout: "/auth"
  },
  {
    path: "/tasks",
    name: "Tasks",
    icon: Dashboard,
    component: TaskPage,
    layout: "/auth"
  },
];
export const landingRoutes =[
  {
    path: "/index",
    component: WelcomePage,
    layout: "/main"
  },
  {
    path: "/login",
    component: LoginPage,
    layout: "/main"
  },
  {
    path: "/register",
    component: RegisterPage,
    layout: "/main"
  },
];
