import { USER } from '../_helpers/config';
import { api } from './axios.service';

export const userService = {
    login,
    logout
};
function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(USER);
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                // location.reload();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
function login(username, password){
    api('POST','/users/authenticate','',JSON.stringify({ username, password }),'')
    .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(USER, JSON.stringify(user));
            console.log(user);
            return user;
        })
}

// function getAll() {
//     const requestOptions = {
//         method: 'GET',
//         headers: authHeader()
//     };

//     return fetch(`${config.apiUrl}/users`, requestOptions).then(handleResponse);
// }

