export const ADD = 'ADD';
export const EDIT ='EDIT'
export const getColor=(c)=>{
    switch(c){
        case "purple" : return '#9c27b0';
        case "blue" : return '#00bcd4';
        case "red" : return '#f44336';
        case "green" : return '#4caf50';
        case "orange" : return '#ff9800';
        default : break;
    }
}