import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { ADD } from '../../../utils';
import { FormControl, InputLabel, MenuItem, TextField } from '@material-ui/core';
import { Select } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(0.5),
      width: "25vw",
    },
  }));
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
 
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function TaskFormModal({state,handleClose,task}) {
    const classes = useStyles();
  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={state.open} maxWidth={'sm'} fullWidth={true}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {state.action === ADD ? 'Create new Task':'edit Task'}
        </DialogTitle>
        <DialogContent dividers>
        <TextField
              required
                id="outlined-basic"
                label="Last name"
                name="lastName"
                variant="outlined"
                // value={profile.lastName}
                // onChange={handleOnChange}
                style={{ width: '25vw',margin:5 }}
              />
              <TextField
              required
                id="outlined-basic"
                label="Last name"
                name="lastName"
                variant="outlined"
                // value={profile.lastName}
                // onChange={handleOnChange}
                style={{ width: '25vw',margin:5 }}
              />
              <FormControl
            variant="outlined"
            className={classes.formControl}
            required

          >
            <InputLabel id="demo-simple-select-outlined-label">
           Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="role"
              name='gender'
                // value={profile.gender}
                // onChange={handleOnChange}
              label="Task Type"
            >
              <MenuItem value={'Homework'}>Male</MenuItem>
              <MenuItem value={'test'}>Female</MenuItem>
            </Select>
            </FormControl>
        </DialogContent>
      </Dialog>
    </div>
  );
}
