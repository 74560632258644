import { Button, CardActionArea, CardContent, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, Typography } from '@material-ui/core'
import { Card,  TextField } from '@material-ui/core'
import React, { useState } from 'react'
import profileImage from '../../assets/img/teacher.png';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import Fab from "@material-ui/core/Fab";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0.5),
    width: "25vw",
  },
}));
const userdetails={
  firstName:'Ranjan',
  lastName:'Kamal',
  email:'rkamal21@gamil.com',
  phoneNumber:'0782534252',
  gender:'Male',
  dob:'1997-07-23',
  grade:'7',
  interests:'caram,cricket'

}
export default function ProfilePage({color}) {
  const initialState = {};
  const [profile,setProfile]=useState(userdetails)
  const [profileUpload, setprofileUpload] = useState(initialState);

  const handleImageFile = (e) => {
    let formdata = new FormData();
    formdata.append("image", e.target.files[0]);
    setprofileUpload({
      ...profileUpload,
      image: formdata,
      file: URL.createObjectURL(e.target.files[0]),
    });
  };

  const handleOnclick = (e) => {
    e.preventDefault();
    console.log({profile})
  };
  const handleOnChange = (e)=>{
    e.preventDefault()
    setProfile({
      ...profile,
      [e.target.name]: e.target.value,
    })
  }
  const classes = useStyles();
    return (
      <>

        <Card style={{width:'60vw'}}>
            {/* <CardContent style={{backgroundColor:color,display:'flex',justifyContent:'center',alignItems:'center',height:15}}>
            <Typography gutterBottom variant="h5" component="h2" style={{color:'white'}}>
             Edit your profile
             </Typography>
            </CardContent> */}
            <div style={{display:'flex',justifyContent:'center',backgroundColor:color,minHeight:70,minWidth:70}}>
                <div style={{display:'flex',alignSelf:'center',flexDirection:'column'}}>
                  <img src={profileUpload.file != undefined ? profileUpload.file: profileImage} style={{height:150,width:150,borderRadius:'50%',minWidth:70,margin:10,alignSelf:'center'}}></img>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    multiple
                    type="file"
                    hidden
                    onChange={handleImageFile}
                  />
                   <label htmlFor="contained-button-file">
              <Fab component="span" style={{float:'right',marginRight:15,marginTop:-50,height:30,width:30}}>
                <PhotoCameraIcon fontSize="small"/>
              </Fab>
            </label>
            </div>
          </div>
              <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
            <Grid style={{margin:60}}>
             <TextField
             required
                id="outlined-basic"
                label="First name"
                name="firstName"
                value={profile.firstName}
                onChange={handleOnChange}
                variant="outlined"
                style={{ width: '25vw',margin:5 }}
              />
              <TextField
              required
                id="outlined-basic"
                label="Last name"
                name="lastName"
                variant="outlined"
                value={profile.lastName}
                onChange={handleOnChange}
                style={{ width: '25vw',margin:5 }}
              />
               <TextField
               required
                id="outlined-basic"
                label="Email Address"
                name="email"
                variant="outlined"
                value={profile.email}
                onChange={handleOnChange}
                style={{ width: '25vw',margin:5 }}
              />
              <TextField
              required
                id="outlined-basic"
                label="Phone number"
                name="phoneNumber"
                variant="outlined"
                type="number"
                style={{ width: '25vw',margin:5 }}
                value={profile.phoneNumber}
                onChange={handleOnChange}
              />
               <FormControl
            variant="outlined"
            className={classes.formControl}
            required

          >
            <InputLabel id="demo-simple-select-outlined-label">
           Gender
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="role"
              name='gender'
                value={profile.gender}
                onChange={handleOnChange}
              label="Gender"
            >
              <MenuItem value={'Male'}>Male</MenuItem>
              <MenuItem value={'Female'}>Female</MenuItem>
            </Select>
          </FormControl>
              <TextField
              required
                id="outlined-basic"
                label="Date of birth"
                type="date"
                name="dob"
                value={profile.dob}
                onChange={handleOnChange}
                variant="outlined"
                style={{ width: '25vw',margin:5 }}
              />
              
              <TextField
              required
                id="outlined-basic"
                label="Grade"
                name="grade"
                value={profile.grade}
                onChange={handleOnChange}
                variant="outlined"
                style={{ width: '25vw',margin:5 }}
              />
               <TextField
               required
                id="outlined-basic"
                label="Interests"
                variant="outlined"
                style={{ width: '25vw',margin:5 }}
              />
              </Grid>
        </div>
            <Button style={{float:'right',margin:10,backgroundColor:color,color:'#fff'}} onClick={handleOnclick}>save changes</Button>
        </Card>
        </>
    )
}
