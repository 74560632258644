import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { ADD } from '../../utils';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
} from "@material-ui/core";
import { courses } from "../../data";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  card: {
    maxWidth: 1000,
    minWidth: 400,
    minHeight: 400,
  },
  cardBg: {
    backgroundColor: "#9c27b0",
    alignItems: "center",
  },
});
const LessonForm = ({ color,routePath,action }) => {
  const history = useHistory()
  const classes = useStyles();
  const { courseId } = useParams();
  const course = courses.find(element => element.id == courseId);
  const [selectedCourse, setSelectedCourse] = useState(course);
  return (
    <Card className={classes.card}>
      <CardContent
        style={{
          backgroundColor: color,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          gutterBottom
          variant="h5"
          component="h2"
          style={{ color: "white" }}
        >
          {action===ADD?'Create New Lesson':`Edit $'s details` }
        </Typography>
      </CardContent>
      <CardContent>
        <div className={classes.root}>
          <Grid container spacing={3}>
          <Grid
              item
              xs={12}
              sm={12}
              style={{
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
                paddingTop: 30,
              }}
            >
           <FormControl className={classes.formControl} disabled style={{ width: 500 }}>
        <InputLabel id="demo-simple-select-disabled-label">Course Name</InputLabel>
        <Select
          labelId="demo-simple-select-disabled-label"
          id="demo-simple-select-disabled"
          value={courseId}
          // onChange={handleChange}
        >
          <MenuItem value={selectedCourse.id}>{selectedCourse.name}</MenuItem>
        </Select>
      </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
                paddingTop: 30,
              }}
            >
             <TextField
                id="outlined-basic"
                label="Lesson name"
                variant="outlined"
                style={{ width: 500 }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
                paddingTop: 30,
              }}
            >
              <TextareaAutosize
                aria-label="minimum height"
                rowsMin={3}
                placeholder="Description of lesson"
                style={{ minWidth: 500 }}
              />
            </Grid>
          </Grid>
        </div>
      </CardContent>
      <CardActions style={{ justifyContent:'flex-end' }} >
        <Button type="submit" style={{backgroundColor:color,color:'#fff'}} onClick={()=>history.push(`${routePath}/${courseId}/lessons`)}>
          Save changes
        </Button>
      </CardActions>
    </Card>
  );
};
export default LessonForm;
