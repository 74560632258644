import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { Draggable, Droppable } from "react-beautiful-dnd";
const TodoColumn = React.memo(function TodoColumn({todoData,handleClickOpen,homeIndex}) {
    const grid = 8;
    const setDragDataList =(stateData)=>{
      return [
        {
        droppableId: "todoContainer",
        tasks: stateData.items,
        taskCardColor: "#ab47bc",
        dragCardColor: "#ffa726",
      },
      {
        droppableId: "onprogressContainer",
        tasks: stateData.progress,
        taskCardColor: "#ffa726",
        dragCardColor: "#66bb6a",
      },
      {
        droppableId: "completeContainer",
        tasks: stateData.completed,
        taskCardColor: "#66bb6a",
        dragCardColor: "#66bb6a",
      },
  ];
}
const [columns,setColumns] = useState(setDragDataList(todoData))
useEffect(()=>{
  setColumns(setDragDataList(todoData))
  console.log({todoData})
},[todoData])
  const getItemStyle = (isDragging, draggableStyle, color, dragColor) => {
    // some basic styles to make the items look a bit nicer
    return {
      userSelect: "none",
      padding: grid * 2,
      margin: `0 0 ${grid}px 0`,
      border: 1,
      borderRadius: 5,
      color: "white",
      // change background colour if dragging
      background: isDragging ? dragColor : color,
      // styles we need to apply on draggables
      ...draggableStyle,
    };
  };

  const getListStyle = (isDraggingOver) => ({
    // background: isDraggingOver && "#69a4b4",
    padding: grid,
    width: '30vh',
    minHeight:'100%'
  });
    return (
        <>
        {columns.map((data,index)=>(<Grid xs={4} style={{ backgroundColor: "" }} key={index}>
          <Droppable droppableId={data.droppableId} isDropDisabled={(index <= homeIndex)}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {/* <h6><span>To Do Tasks</span></h6> */}
                {data.tasks.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                          data.taskCardColor,
                          data.dragCardColor
                        )}
                        onClick={()=>handleClickOpen(item)}
                      >
                        {item.content}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </Grid>))}
        </>
    )
})
export default  TodoColumn;
