import React, { useEffect, useState } from 'react'
import TableCell from "@material-ui/core/TableCell";
import { FormControlLabel, Switch } from '@material-ui/core';
export default function EnrollCell({cell,changeSelectenrollCells,enrollCells}) {
    const [isEnroll,setEnroll]=useState(false)
useEffect(()=>{
    if(enrollCells.includes(cell.index)){
        setEnroll(true)
    }
},[])
    return (
        <TableCell>{cell.index}
            <FormControlLabel
                    control={
                      <Switch
                      checked={isEnroll}
                      disabled={!cell.isAvailable}
                        onChange={(e) => {
                            changeSelectenrollCells(e.target.checked,cell.index)
                            setEnroll(e.target.checked)
                            // console.log({
                            //   enrolled: e.target.checked,
                            //   cellIndex:cell.index,
                            // })
                        }}/>}
                        />
          </TableCell>
    )
}
