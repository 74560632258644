import React, { useEffect, useState } from 'react'
import TableCell from "@material-ui/core/TableCell";
import { studentEnrollcells } from '../../data';
export default function StudentEnrollCell({cell}) {
    const [cellStudents,setcellStudents] =useState([])
    const [isExpand,setExpand] =useState(false)
    const getStudentsByCellIndex=(index)=>{
      return studentEnrollcells.find(enrollcell=>enrollcell.cellIndex===index)?.students
    }
useEffect(()=>{
    const resStudents =getStudentsByCellIndex(cell.index)
   setcellStudents(resStudents!=undefined ? resStudents:[])
},[])
    return (
        <TableCell style={{verticalAlign:'top'}}>
           {
              isExpand ? <> 
                <div style={{backgroundColor:'#f43688',color:'#fff',display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'row',cursor:'pointer',marginBottom:3}} onClick={()=>setExpand(false)}>close the details</div>
               {cellStudents?.map(cstudent => (
                   <div key={`${cstudent.id}-${cell.index}`} style={{height:30, backgroundColor:cstudent.isEnroll?'#098c53':'#f4ae36',color:'#fff',marginBottom:3,display:'flex',alignItems:'center',justifyContent:'center'}}><p>{cstudent?.name}</p></div>
               ))} </>: (
                    <div style={{width:250,color:'#fff',marginBottom:3,display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'row'}} className="enroll-cell" onClick={()=>setExpand(true)}>
                        <div style={{width:'50%', backgroundColor:'#f4ae36',display:'flex',justifyContent:'center',borderRight:"solid #0000FF",flexDirection:'column'}}><span style={{justifySelf:'center',alignSelf:'center'}}>new students</span> <span style={{justifySelf:'center',alignSelf:'center'}}>{cellStudents.filter(cs=>!cs.isEnroll).length}</span></div>
                        <div style={{width:'50%', backgroundColor:'#098c53',display:'flex',justifyContent:'center',flexDirection:'column'}}><span style={{justifySelf:'center',alignSelf:'center'}}>Enroled students</span> <span style={{justifySelf:'center',alignSelf:'center'}}>{cellStudents.filter(cs=>cs.isEnroll).length}</span> </div>
                    </div>
               )
           } 
        </TableCell>
    )
}
