import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Grid, TextareaAutosize, TextField } from "@material-ui/core";
import RegularButton from "../common/Button";
import ColorPicker from "material-ui-color-picker";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { courses ,teachers} from "../../data";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  card: {
    maxWidth: 1000,
    minWidth: 350,
    // minHeight: 350,
  },
  cardBg: {
    backgroundColor: "#9c27b0",
    alignItems: "center",
  },
});
const MentorForm = ({ color }) => {
  const classes = useStyles();
  const [c, setC] = useState("#9c27b0");
  const [selectedCourse, setSelectedCourse] = useState();
  const [selectedTeacher, setSelectedTeacher] = useState();
  return (
    <Card className={classes.card}>
      <CardContent
        style={{
          backgroundColor: color,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          gutterBottom
          variant="h5"
          component="h2"
          style={{ color: "white" }}
        >
          Create New mentor
        </Typography>
      </CardContent>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                paddingTop: 5,
              }}
            >
              <Autocomplete
                style={{ width: 500 }}
                id="free-solo-demo"
                freeSolo
                onChange={(event, newValue) => {
                  newValue != null
                    ? setSelectedTeacher(newValue.id)
                    : setSelectedTeacher(null);
                  console.log({ newValue });
                }}
                options={teachers}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Teacher"
                    margin="normal"
                    variant="outlined"
                  />
                )}
              />
            <div style={{flexDirection:"row",display:'flex'}}>
              
              <Autocomplete
                style={{ width: 450 }}
                id="free-solo-demo"
                freeSolo
                onChange={(event, newValue) => {
                  newValue != null
                    ? setSelectedCourse(newValue.id)
                    : setSelectedCourse(null);
                  console.log({ newValue });
                }}
                options={courses}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Course"
                    margin="normal"
                    variant="outlined"
                  />
                )}
              />
              <div  style={{
                display:'flex',
                    backgroundColor: c,
                    width: 50,
                    height: 50,
                    alignSelf:'center',
                    justifySelf:'center',
                    marginLeft: 10,
                    cursor: "pointer",
                  }}>
                <ColorPicker
                  showPicker={true}
                  // name='color'
                  // defaultValue={c}
                  value={c}
                  onChange={(c) => {
                    console.log(c);
                    setC(c);
                  }}
                />
                </div>
                </div>
              <TextareaAutosize
                aria-label="minimum height"
                rowsMin={3}
                placeholder="Minimum 3 rows"
                style={{ minWidth: 500 }}
              />
            
            </Grid>
          </Grid>
          <CardActions>
            
          </CardActions>
          <RegularButton style={{ float: "right",marginRight:10, backgroundColor:color }} type="submit">
            Save changes
          </RegularButton>
        </div>
    </Card>
  );
};
export default MentorForm;
