import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import TaskModal from "../modals/TaskModal";
import TodoColumn from "./TodoColumn";
// const todoTaskColor='#ab47bc'
// const progressTaskColor='#ffa726'
// const completeTaskColor='#66bb6a'

export default function ToDoContainer({ lessonTasks }) {
  const { todoTasks, completedTasks, progressTasks } = lessonTasks;
  const [task, setTask] = useState();
  const [open, setOpen] = React.useState(false);
  const columnOrder = ["todoContainer", "onprogressContainer", "completeContainer"];
  const [state, setState] = useState({
    items: todoTasks,
    progress: progressTasks,
    completed: completedTasks,
  });

  const handleClickOpen = (item) => {
    setTask(item);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  /**
   * Moves an item from one list to another list.
   */
  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

 
  const [homeIndex, setHomeIndex] = useState(null);
  /**
   * A semi-generic way to handle multiple lists. Matches
   * the IDs of the droppable container to the names of the
   * source arrays stored in the state.
   */
  const id2List = {
    todoContainer: "items",
    onprogressContainer: "progress",
    completeContainer: "completed",
  };

  const getList = (id) => state[id2List[id]];
  const onDragStart = (start) => {
    const index = columnOrder.indexOf(start.source.droppableId);
    setHomeIndex(index);
  };
  const onDragEnd = (result) => {
    setHomeIndex(null);
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }
    console.log({
      dropId: source.droppableId,
      destination: destination.droppableId,
    });
    console.log({ sourceIndex: source.index, destIndex: destination.index });
    if (source.droppableId === destination.droppableId) {
      const reorderTasks = reorder(
        getList(source.droppableId),
        source.index,
        destination.index
      );
      console.log({ reorderTasks, state: source.droppableId });
      if (source.droppableId === "todoContainer") {
        setState({
          ...state,
          items: reorderTasks,
        });
      }
      if (source.droppableId === "onprogressContainer") {
        setState({
          ...state,
          progress: reorderTasks,
        });
      }
      if (source.droppableId === "completeContainer") {
        setState({
          ...state,
          completed: reorderTasks,
        });
      }
    } else {
      const resultArr = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      );
      console.log({ resultArr });

      setState({
        items: resultArr.todoContainer ? resultArr.todoContainer : state.items,
        progress: resultArr.onprogressContainer ? resultArr.onprogressContainer : state.progress,
        completed: resultArr.completeContainer ? resultArr.completeContainer : state.completed,
      });
    }
  };
  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      {console.log({task})}
      <TaskModal open={open} handleClose={handleClose} task={task} />
      <Grid
        xs={12}
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "space-between",
        }}
      >
        <TodoColumn
          todoData={state}
          handleClickOpen={handleClickOpen}
          modalVisible={open}
          homeIndex={homeIndex}
        />
      </Grid>
    </DragDropContext>
  );
}
