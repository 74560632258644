import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import CourseTimeTable from '../../components/auth/CourseTimeTable';
import MentorCard from '../../components/auth/MentorCard';
import MentorForm from '../../components/auth/MentorForm';

const MentorPage =({color})=>{
    const {path} = useRouteMatch();
    return(
        <>
        <Switch>
            <Route exact path={`${path}`} component={()=><MentorCard routePath={path} color={color}/>}/>
            <Route exact path={`${path}/add-mentor`} component={()=><MentorForm routePath={path} color={color}/>}/>
            <Route exact path={`${path}/:mentorId`} component={()=><CourseTimeTable routePath={path} />}/>
        </Switch>
        </>
    );
}
export default MentorPage;