import React from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import CourseCard from '../../components/auth/CourseCard';
import LessonCard from '../../components/auth/LessonCard';
import LessonForm from '../../components/auth/LessonForm';
import { courses } from '../../data';
import CourseForm from '../../components/auth/CourseForm';
import { ADD, EDIT, getColor } from '../../utils';
import LessonTasks from '../../components/auth/LessonTasks';

const CoursePage =({color})=>{
   
    console.log({color:getColor(color)})
  const history =useHistory();
  const {path} = useRouteMatch();
    const createCourse=(obj)=>{
        console.log({...obj,id:courses.length +1})
        courses.push({...obj,id:courses.length +1});
        history.push(`${path}`)
    }
    return(
        <>
        <Switch>
            <Route exact path={`${path}`} component={()=><CourseCard routePath={path} courses={courses}/>}/>
            <Route exact path={`${path}/add-course`} component={()=><CourseForm routePath={path} color={getColor(color)} createCourse={(obj)=>createCourse(obj)} action={ADD}/>}/>
            <Route exact path={`${path}/:courseId/edit`} component={()=><CourseForm routePath={path} color={getColor(color)} createCourse={(obj)=>createCourse(obj)} action={EDIT}/>}/>
            <Route exact path={`${path}/:courseId/lessons`} component={()=><LessonCard routePath={path} />}/>
            <Route exact path={`${path}/:courseId/add-lesson`} component={()=><LessonForm routePath={path} color={getColor(color)} action={ADD}/>}/>
            <Route exact path={`${path}/:courseId/lessons/:lessonId/edit`} component={()=><LessonForm routePath={path} color={getColor(color)} action={EDIT}/>}/>
            <Route exact path={`${path}/:courseId/lessons/:lessonId/tasks`} component={()=><LessonTasks routePath={path} color={getColor(color)}/>}/>
        </Switch>
        </>
    );
}
export default CoursePage;