import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Box, Grid } from '@material-ui/core';
import RegularButton from '../common/Button';
import { useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  card: {
    maxWidth: '80%',
    minWidth : 100,
    minHeight:350
  },
  cardBg:{
    backgroundColor:'#9c27b0',
    alignItems:'center'
  }
});
const options = [
  {name:'Create Lesson',route:'add-lesson'},
  {name:'View Lessons',route:'lessons'},
 {name: 'Edit Course',route:'edit'},
];
const CourseCardComponent = ({course, routePath,key })=>{
  const classes = useStyles();
 const history =useHistory();
 const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return(
    <Card className={classes.card} key={key}>
        <CardContent style={{backgroundColor:course.color,display:'flex',alignItems:'center',justifyContent:'center',height:15}}>
        <Box style={{float:'center'}} >
          <Typography gutterBottom variant="h5" component="h2" style={{color:'white'}}>
           {course.name}
          </Typography>
        </Box>
          <Box >
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 200,
            width: '20ch',
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} onClick={()=>history.push(`${routePath}/${course.id}/${option.route}`)}>
            {option.name}
          </MenuItem>
        ))}
      </Menu>
          </Box>
    </CardContent>
      <CardActions>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12} style={{backgroundColor:'white',height:232,display:'flex',alignItems:'center',justifyContent:'center'}}>
              <div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <h6 style={{textAlign:'center'}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has be Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has be</h6>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Button style={{display: 'flex', alignItems: 'center', justifyContent: 'center',backgroundColor:course.color,height:50,width:200,borderRadius:10}} onClick={() => history.push(`${routePath}/${course.id}/lessons`,routePath)}>
                    <h4 style={{color:'white'}}>
                      Go to Lessons
                    </h4>
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </CardActions>
    </Card>
  )
}
const CourseCard = ({routePath,courses}) =>{
  const history =useHistory();
  const classes = useStyles();
  return (
    <>
    <div className={classes.root}>
      <Grid container spacing={2}>
        <RegularButton style={{ left:'65vw' }} onClick={() => history.push(`${routePath}/add-course`)}>New Course</RegularButton>
        {courses.map(course=>(
        <Grid item xs={12} md={12} sm={6}>
          <CourseCardComponent course={course} routePath={routePath} key={course.id}/>
        </Grid>
          ))}
      </Grid>
    </div>
    </>
  );
}
export default CourseCard;