import React, { useState } from "react";
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import logo from "../../assets/img/logo.png";
import { Router, useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0.5),
    width: "80%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const RegisterPage = () => {
  const history =useHistory();
  const classes = useStyles();
  return (
    <Grid
      style={{
        display: "flex",
        backgroundColor: "gray",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Card
        style={{
          display: "flex",
          WebkitFlexWrap: "wrap",
          minHeight: "60vh",
          minWidth: "35vw",
          maxWidth: "35vw",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
       <Grid
          md={12}
          xs={12}
          style={{
            display: "flex",
            height: '23vh',
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img style={{  width: "150px" }} src={logo}></img>
        </Grid>

        <Grid
          style={{
            display: "flex",
            flexWrap: "inherit",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TextField
            id="outlined-helperText"
            label="Email"
            required
            // value={username}
            className={classes.formControl}
            variant="outlined"
            // onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            id="outlined-helperText"
            label="First name"
            // value={username}
            variant="outlined"
            required
            className={classes.formControl}
            // onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            id="outlined-helperText"
            label="Last name"
            required
            // value={username}
            variant="outlined"
            className={classes.formControl}
            // onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            id="outlined-helperText"
            label="Password"
            // value={username}
            variant="outlined"
            className={classes.formControl}
            required
            // onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            id="outlined-helperText"
            label="Confirm password"
            // value={username}
            variant="outlined"
            className={classes.formControl}
            required
            // onChange={(e) => setUsername(e.target.value)}
          />
          <FormControl
            variant="outlined"
            className={classes.formControl}
            required
          >
            <InputLabel id="demo-simple-select-outlined-label">
            Register as a
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="role"
              //   value={age}
              //   onChange={handleChange}
              label="Register as a"
            >
              <MenuItem value={'Student'}>Student</MenuItem>
              <MenuItem value={'Teacher'}>Teacher</MenuItem>
              {/* <MenuItem value={30}>Principal</MenuItem> */}
            </Select>
          </FormControl>
          <Grid style={{flexDirection:'column'}}>
          <p style={{marginLeft:25,margin:3}}>if you have an account? , please <a href="#" style={{color:'blueviolet', fontSize:16,textDecoration: 'underline'}} onClick={()=>history.push('/main/login')}> Signin </a> here</p>
          <FormControlLabel
            value="start"
            control={<Checkbox color="primary" />}
            label="Accept Terms and Condition"
            labelPlacement="end"
            style={{margin:2}}
          />
          <a href="#">view terms and conditions</a>
          </Grid>
        </Grid>
        <CardActions>
          <Button
            style={{ width: "250px", float: "right", marginBottom: 15 }}
            variant="contained"
            size="medium"
            color="primary"
          >
            Register
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};
export default RegisterPage;
