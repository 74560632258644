import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Grid, TextField } from '@material-ui/core';
import RegularButton from '../common/Button';
import ColorPicker from 'material-ui-color-picker';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { ADD } from '../../utils';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  card: {
    maxWidth: 1000,
    minWidth : 400,
    // height:350
  },
  cardBg:{
    backgroundColor:'#9c27b0',
    alignItems:'center'
  }
});
const CourseForm = ({color,createCourse,action })=>{
  const classes = useStyles();
  const [state,setState]= useState({
    color :'#9c27b0',
    courseName:null
  });

  return(
    <Card className={classes.card}>
        <CardContent style={{backgroundColor:color,display:'flex',alignItems:'center',justifyContent:'center'}}>
          <Typography gutterBottom variant="h5" component="h2" style={{color:'white'}}>
           {action===ADD?"Create new course":"Edit course"}
          </Typography>
        </CardContent>
      <CardActions>
        <div className={classes.root}>
          <Grid   container spacing={3}>
            <Grid item xs={12} sm={12} style={{backgroundColor:'white',display:'flex',justifyContent:'center',alignItems:'center' , flexDirection:"row",paddingTop:30}}>
            <TextField
              id="outlined-helper-text"
              required
              onChange={e => {
                console.log(e.target.value)
                setState({
                  ...state,
                  courseName:e.target.value
                })
              }}
              label="Course Name"
              style={{maxWidth:500}}
              variant="outlined"
              rowsMax ={10}
              fullWidth={true}
            />
            <Grid style={{ backgroundColor:state.color,display:'flex',maxWidth:50, maxHeight:50,marginLeft:10,cursor:'pointer'}}>
            <ColorPicker
                      showPicker={true}
                        value={state.color} 
                        onChange={c => {
                          console.log(c)
                          setState({
                            ...state,
                            color:c
                          })
                        }}
                      
                      />
                      </Grid>
            </Grid> 
            <Grid item xs={12} sm={12} style={{backgroundColor:'white',display:'flex',justifyContent:'center',alignItems:'center' , flexDirection:"row",paddingTop:30}}>
            <TextareaAutosize aria-label="minimum height" 
            rowsMin={3} placeholder="Minimum 3 rows" 
            style={{width:550}}
            />  
            </Grid>  
          </Grid>
          
          <RegularButton style={{float:'right', marginTop:50,backgroundColor:color}} type="submit" onClick={()=>createCourse({color:state.color,name:state.courseName})}>Save changes</RegularButton>
        </div>
      </CardActions>
    </Card>
  )
}
export default CourseForm;