import { userConstants } from '../_types';
import { userService } from '../_services';
import { history } from '../_helpers/history';
import { USER } from '../_helpers/config';

export const userActions = {
    login,
    logout
};

function login(username, password) {
    return dispatch => {
        dispatch(request({ username }));
        // userService.login(username, password)
        //     .then(
        //         user => { 
        //             dispatch(success(user));
        //             // history.push('/');
        //         },
        //         error => {
        //             dispatch(failure(error));
        //             // dispatch(alertActions.error(error));
        //         }
        //     );
        if(username==='admin@gmail.com' && password==='admin'){
            let user ={username:'admin',id:1}
            dispatch(success(user));
            localStorage.setItem(USER, JSON.stringify(user));
            history.push('/auth');
        }else{
            dispatch(failure({message:'check username or password'}));
        }
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
    userService.logout();
    history.push('/main');
    return { type: userConstants.LOGOUT };
}

