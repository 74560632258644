import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import teacher from '../../assets/img/teacher.png';
import RegularButton from './../../components/common/Button';
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useEffect } from 'react';
import { courses, mentorsArr } from '../../data';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  card: {
    maxWidth: 700,
    minWidth : 200,
    minHeight:300
  },
  cardBg:{
    backgroundColor:'#9c27b0',
    alignItems:'center'
  }
});
const MentorCardComponent = ({mentor, routePath })=>{
  const classes = useStyles();
 const history =useHistory();
  return(
    <Card className={classes.card}>
        <CardContent style={{backgroundColor:mentor.color,display:'flex',alignItems:'center',justifyContent:'center'}}>
          <Typography gutterBottom variant="h5" component="h2" style={{color:'white'}}>
           {mentor.course.name}
          </Typography>
        </CardContent>
      <CardActions>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item sm={3} lg={3} xs={12} style={{backgroundColor:mentor.color,display:'flex',alignItems:'center',justifyContent:'center'}}>
              <div>
                <div style={{display:'flex',alignItems:'center',justifyContent:'center',minHeight:70,minWidth:70}}>
                  <img src={teacher} style={{maxHeight:150,maxWidth:150,minWidth:70}}></img>
                </div>
                <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                  <h3 style={{color:'white'}}>Mr.{mentor.name}</h3>
                </div>
              </div> 
            </Grid>
            <Grid item sm={9} lg={9} xs={12} style={{backgroundColor:'white',minHeight:232,display:'flex',alignItems:'center',justifyContent:'center'}}>
              <div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <h6 style={{textAlign:'center'}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has be Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has be</h6>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Button style={{display: 'flex', alignItems: 'center', justifyContent: 'center',backgroundColor:mentor.color,height:50,minWidth:150,borderRadius:10}} onClick={() => history.push(`${routePath}/${mentor.id}`)}>
                    <h4 style={{color:'white'}}>
                      Enroll now
                    </h4>
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </CardActions>
    </Card>
  )
}
const MentorCard = ({routePath,color}) =>{
  const history =useHistory();
  const classes = useStyles();
  const [selectedCourse, setSelectedCourse] = useState();
 const [selectedMentor,setselectedMentor] = useState();
 const [mentors,setMentors] = useState(mentorsArr);
 const searchMentors=(selectCourse,selectMentor)=>{
mentorsArr.filter(mentor=>mentor.course.id == selectCourse)
 }
 useEffect(()=>{
  selectedCourse !=null ? setMentors(mentorsArr.filter(mentor=>mentor.course.id = selectedCourse)):setMentors(mentorsArr)
console.log({selectedCourse})
 },[selectedCourse])

 useEffect(()=>{
    selectedMentor!=null ? setMentors(mentorsArr.filter(mentor=>mentor.id=selectedMentor)):setMentors(mentorsArr)
  console.log({selectedMentor})
   },[selectedMentor])
  return (
    <>
    <div className={classes.root}>
      <Grid style={{display:"flex",flexDirection:'row'}}>
      <Grid xs={12} sm={8} lg={8} container spacing={2}>
        <RegularButton style={{ left:'65vw' }} onClick={() => history.push(`${routePath}/add-mentor`)}>Create New Mentor</RegularButton>
        {mentors.map(mentor=>(
        <Grid item xs={12} md={12} sm={6}>
          <MentorCardComponent mentor={mentor} routePath={routePath} key={mentor.id}/>
        </Grid>
          ))}
      </Grid>
      <Grid xs={12} sm={4} lg={4} style={{marginTop:50, marginLeft:10}}>
      <Card className={classes.card}>
      <CardContent style={{display:'flex',backgroundColor:color,alignItems:'center',justifyContent:'center',height:15}}>
      <FilterListIcon style={{ color:'white'}}/>
          <Typography gutterBottom variant="h6" component="h2" style={{ color:'white'}}>
         Filters
          </Typography>
        </CardContent>
        <CardActions >
          <Grid style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
        <Autocomplete
        style={{ width:300}}
        id="free-solo-demo"
        freeSolo
        onChange={(event, newValue) => newValue!= null ? setselectedMentor(newValue.id):setselectedMentor(null)}
        options={mentorsArr}
        getOptionLabel={option => option.name}
        renderInput={(params) => (
          <TextField {...params} label="Search By Mentor" margin="normal" variant="outlined" />
        )}
      />
       <Autocomplete
        style={{ width:300}}
        id="free-solo-demo"
        freeSolo
        onChange={(event, newValue) =>{ newValue!= null ? setSelectedCourse(newValue.id):setSelectedCourse(null)
        console.log({newValue})}}
        options={courses}
        getOptionLabel={option => option.name}
        renderInput={(params) => (
          <TextField {...params} label="Search By Course" margin="normal" variant="outlined" />
        )}
      />
      </Grid>
        </CardActions>
        </Card>
      </Grid>
      </Grid>
    </div>
    </>
  );
}
export default MentorCard;