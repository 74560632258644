const teachers =[
  {
    id:1,
    name : 'R.Kamal'
  },
  {
    id:2,
    name : 'A.John'
  },
  {
    id:3,
    name : 'L.Peter'
  },
  {
    id:4,
    name : 'M.Mohan'
  },
  {
    id:5,
    name : 'T.Senthil'
  },
];
const studentEnrollcells =[
  {
    cellIndex:1,
    students:[
      {
        id:1,
        name : 'R.Kamal',
        isEnroll:true
      },
      {
        id:4,
        name : 'M.Mohan',
        isEnroll:false,
      },
      {
        id:5,
        name : 'T.Senthil',
        isEnroll:false
      },
    ]
  },
  {
    cellIndex:2,
    students:[
      {
        id:1,
        name : 'R.Kamal',
        isEnroll:true
      },
      {
        id:2,
        name : 'A.John',
        isEnroll:true
      },
      {
        id:3,
        name : 'T.Senthil',
        isEnroll:true
      },
      {
        id:4,
        name : 'R.Kamal',
        isEnroll:true
      },
      {
        id:5,
        name : 'A.John',
        isEnroll:true
      },
      {
        id:6,
        name : 'T.Senthil',
        isEnroll:true
      },
      {
        id:7,
        name : 'R.Kamal',
        isEnroll:true
      },
      {
        id:8,
        name : 'A.John',
        isEnroll:true
      },
      {
        id:9,
        name : 'T.Senthil',
        isEnroll:true
      },
    ]
  },
  {
    cellIndex:3,
    students:[
      {
        id:1,
        name : 'R.Kamal',
        isEnroll:true
      },
      {
        id:2,
        name : 'A.John',
        isEnroll:true
      },
      {
        id:5,
        name : 'T.Senthil',
        isEnroll:true
      },
    ]
  },
  {
    cellIndex:4,
    students:[
      {
        id:1,
        name : 'R.Kamal',
        isEnroll:true
      },
      {
        id:2,
        name : 'A.John',
        isEnroll:true
      },
      {
        id:3,
        name : 'T.Senthil',
        isEnroll:true
      },
      {
        id:4,
        name : 'M.Mohan',
        isEnroll:false,
      },
      {
        id:5,
        name : 'T.Senthil',
        isEnroll:false
      },
    ]
  },
  {
    cellIndex:5,
    students:[
      {
        id:1,
        name : 'R.Kamal',
        isEnroll:true
      },
      {
        id:2,
        name : 'A.John',
        isEnroll:true
      },
      {
        id:3,
        name : 'L.Peter',
        isEnroll:true
      },
      {
        id:4,
        name : 'M.Mohan',
        isEnroll:false,
      },
      {
        id:5,
        name : 'T.Senthil',
        isEnroll:false
      },
    ]
  },
  {
    cellIndex:6,
    students:[
      {
        id:1,
        name : 'R.Kamal',
        isEnroll:true
      },
      {
        id:2,
        name : 'A.John',
        isEnroll:false
      },
      {
        id:5,
        name : 'T.Senthil',
        isEnroll:true
      },
    ]
  },
  {
    cellIndex:7,
    students:[
      {
        id:1,
        name : 'R.Kamal',
        isEnroll:true
      },
      {
        id:2,
        name : 'A.John',
        isEnroll:true
      },
      {
        id:4,
        name : 'M.Mohan',
        isEnroll:false,
      },
      {
        id:5,
        name : 'T.Senthil',
        isEnroll:false
      },
    ]
  },
  {
    cellIndex:8,
    students:[
      {
        id:1,
        name : 'R.Kamal',
        isEnroll:true
      },
      {
        id:2,
        name : 'A.John',
        isEnroll:true
      },
      {
        id:5,
        name : 'T.Senthil',
        isEnroll:true
      },
    ]
  },
  {
    cellIndex:9,
    students:[
      {
        id:1,
        name : 'R.Kamal',
        isEnroll:true
      },
      {
        id:2,
        name : 'A.John',
        isEnroll:true
      },
      {
        id:5,
        name : 'T.Senthil',
        isEnroll:false
      },
    ]
  },
 
  
]
const mentorsArr =[
    {
      id:1,
      course:{
        id:1,
        name:'French'
      },
      color:"#429DF1",
      name : 'R.Kamal'
    },
    {
      id:2,
      course:{
        id:5,
        name:"French",
      },
      color:"#ffa726",
      name : 'A.John'
    },
    {
      id:3,
      course:{
        id:2,
        name:'Mathematics'
      },
      color:"#ef5350",
      name : 'L.Peter'
    },
    {
      id:4,
      course:{
        id:4,
      name:"Tamil",
      },
      color:"#26c6da",
      name : 'M.Mohan'
    },
    {
      id:5,
      course:{
        id:2,
        name:"Mathematics",
      },
      color:"#ec407a",
      name : 'T.Senthil'
    },
  ]
  const courses =[
    {
      id:1,
      name:"French",
      color:"#429DF1"
    },
    {
      id:2,
      name:"Mathematics",
      color:"#ffa726"
    },
    {
      id:3,
      name:"English",
      color:"#ef5350"
    },
    {
      id:4,
      name:"Tamil",
      color:"#26c6da"
    }
  ]

  const getItems = (count, offset = 0) =>
    Array.from({ length: count }, (v, k) => k).map(k => ({
        id: `task-${k + offset}`,
        content: `task ${k + offset}`
    }));
  const lessons =[
    { 
      id:1,
      name:'lesson 1',
      courseId:2,
      color:"#ec407a",
      tasks:{
       todoTasks: [
         {id:`task-1`,content:'Projectile motion'},
         {id:`task-2`,content:'Velocity and acceleration in Two Dimensions'},
       ],
       completedTasks : [
         {id:`task-3`,content:'Analyze Free fall motion under the influence of gravity'}
       ],
       progressTasks: [
         {id:`task-4`,content:'Two Dimensions Displacement'},
         {id:`task-5`,content:'homework'},
       ]
     }
  },
     { 
         id:2,
         name:'lesson 2',
         courseId:2,
         color:"#ec407a",
         tasks:{
          todoTasks: getItems(7),
          progressTasks: getItems(2,8),
          completedTasks :getItems(0), 
        }
     },
     { 
         id:3,
         name:'lesson 3',
         courseId:2,
         color:"#ec407a",
         tasks:{
          todoTasks: getItems(12),
          completedTasks : getItems(0),
          progressTasks: getItems(0)
        }
     },
     { 
      id:4,
       name:'lesson 4',
       courseId:2,
       color:"#ec407a",
        tasks:{
          todoTasks: getItems(10),
          completedTasks : getItems(3, 10),
          progressTasks: getItems(5,13)
        }
   },
     { 
      id:5,
       name:'lesson 5',
       courseId:2,
       color:"#ec407a",
       tasks:{
        todoTasks: getItems(8),
        completedTasks : getItems(0),
        progressTasks: getItems(0)
      }
   },
 ]
 const weeks = [
  { id: 1, name: "Sunday" },
  { id: 2, name: "Monday" },
  { id: 3, name: "Tuesday" },
  { id: 4, name: "Wednesday" },
  { id: 5, name: "Thursday" },
  { id: 6, name: "Friday" },
  { id: 7, name: "Saturday" },
];
function createData(id,time) {
  return {id, time };
}
const rows = [
  createData(1,"09.00 AM to 10.30 AM"),
  createData(2,"11.00 PM to 2.00 PM"),
  createData(3,"02.00 PM to 03.30 PM"),
  createData(4,"09.00 PM to 10.30 PM"),
  // createData("09.00 PM to 10.30 PM", 356, 16.0, 49, 3.9),
];
const cells= [
  {
    index:1,
    rowId:1,
    isAvailable:false
  },
  {
    index:2,
    rowId:1,
    isAvailable:true
  },
  {
    index:3,
    rowId:1,
    isAvailable:false
  },
  {
    index:4,
    rowId:1,
    isAvailable:true
  },
  {
    index:5,
    rowId:1,
    isAvailable:false
  },
  {
    index:6,
    rowId:1,
    isAvailable:true
  },
  {
    index:7,
    rowId:1,
    isAvailable:false
  },
  {
    index:8,
    rowId:2,
    isAvailable:false
  },
  {
    index:9,
    rowId:2,
    isAvailable:true
  },
  {
    index:10,
    rowId:2,
    isAvailable:false
  },
  {
    index:11,
    rowId:2,
    isAvailable:true
  },
  {
    index:12,
    rowId:2,
    isAvailable:true
  },
  {
    index:13,
    rowId:2,
    isAvailable:false
  },
  {
    index:14,
    rowId:2,
    isAvailable:false
  },
  {
    index:15,
    rowId:3,
    isAvailable:true
  },
  {
    index:16,
    rowId:3,
    isAvailable:false
  },
  {
    index:17,
    rowId:3,
    isAvailable:false
  },
  {
    index:18,
    rowId:3,
    isAvailable:true
  },
  {
    index:19,
    rowId:3,
    isAvailable:false
  },
  {
    index:20,
    rowId:3,
    isAvailable:false
  },
  {
    index:21,
    rowId:3,
    isAvailable:true
  },
  {
    index:22,
    rowId:4,
    isAvailable:true
  },
  {
    index:23,
    rowId:4,
    isAvailable:false
  },
  {
    index:24,
    rowId:4,
    isAvailable:true
  },
  {
    index:25,
    rowId:4,
    isAvailable:false
  },
  {
    index:26,
    rowId:4,
    isAvailable:true
  },
  {
    index:27,
    rowId:4,
    isAvailable:false
  },
  {
    index:28,
    rowId:4,
    isAvailable:true
  }
]
  const CLIENT_ID='854125478360-0ao2ccu882sd8oj996vtknnjrksrnu08.apps.googleusercontent.com'
  const secretkey='-csR9-tDOvTPs6SSWm5PZCkz'
  export {courses,mentorsArr,teachers,studentEnrollcells,lessons,rows,weeks,cells,CLIENT_ID}
