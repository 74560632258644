import React, { useState } from "react";
import {
  Button,
  Card,
  Grid,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { userActions } from "../../redux/_actions";
import logo from "../../assets/img/logo.png";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import GoogleBtn from "../../components/base/GoogleBtn";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0.5),
    width: "80%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const LoginPage = () => {
  const history = useHistory();
  const classes = useStyles();
  const [username, setUsername] = useState("admin@gmail.com");
  const [password, setPassword] = useState("admin");
  const dispatch = useDispatch();
  return (
    <Grid
      style={{
        display: "flex",
        backgroundColor: "gray",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Card
        style={{
          display: "flex",
          WebkitFlexWrap: "wrap",
          minHeight: "35vh",
          minWidth: "20vw",
          maxWidth: "30vw",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid
          md={12}
          xs={12}
          style={{
            display: "flex",
            height: 200,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img style={{  width: "150px" }} src={logo}></img>
        </Grid>

        <Grid
          style={{
            display: "flex",
            flexWrap: "inherit",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TextField
            id="outlined-helperText"
            label="Email"
            required
            value={username}
            className={classes.formControl}
            variant="outlined"
            onChange={(e) => setUsername(e.target.value)}
          />

          <TextField
            id="outlined-helperText"
            type="password"
            label="Password"
            value={password}
            variant="outlined"
            onChange={(e) => setPassword(e.target.value)}
            className={classes.formControl}
            required
          />
          <Grid>
         <a href="#" style={{float:'right',textDecoration: "underline"}}>Forget Password?</a>
         <br/>
          <p style={{margin:4}}>
            If you don't have an account , you can &nbsp;
            <a
              href="#"
              style={{
                color: "blueviolet",
                fontSize: 16,
                textDecoration: "underline",
              }}
              onClick={() => history.push("/main/register")}
            >
              Register
            </a>{" "}
            here
          </p>
          </Grid>
          <Button
            style={{ width: "250px",alignContent:'center', marginBottom: 2 }}
            variant="contained"
            size="medium"
            color="primary"
            onClick={() => dispatch(userActions.login(username, password))}
          >
            Login
          </Button>
        </Grid>
            <Grid style={{marginBottom:10}}>
          <p style={{textAlign:'center'}}>OR</p>
          <GoogleBtn/>
            </Grid>
            
      </Card>
    </Grid>
  );
};
export default LoginPage;
