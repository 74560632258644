import { Button, Card, CardActions, CardContent, Grid, makeStyles, TextField } from '@material-ui/core'
import { Router } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useState } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom';
import LessonTasks from '../../components/auth/LessonTasks';
import { courses, lessons, mentorsArr } from '../../data';
import { getColor } from '../../utils';
const useStyles = makeStyles({
    root: {
      flexGrow: 1,
    },
    card: {
      maxWidth: 400,
      minWidth: 200,
      minHeight: 200,
    },
    cardBg: {
      backgroundColor: "#9c27b0",
      alignItems: "center",
    },
  });
export default function TaskPage({color}) {
    const classes = useStyles();
    const history = useHistory()
    const [state,setState]=useState({
        courseId:null,
        lessonId:null
    })

    const handleOnclick = (e) => {
        e.preventDefault();
        console.log({state})
        history.push(`courses/${state.courseId}/lessons/${state.lessonId}/tasks`)
      };
      const handleOnChange = (e)=>{
          console.log(e)
        e.preventDefault()
        setState({
          ...state,
          [e.target.name]: e.target.value,
        })
      }
    return (
        <Switch>
             <Route exact path={`auth/courses/:courseId/lessons/:lessonId/tasks`} component={()=><LessonTasks routePath={'auth/courses'} color={getColor(color)}/>}/>
             <Route exact path={`/auth/tasks`} render={
                 ()=>
                 (<Grid style={{display:'flex',justifyContent:'center'}}>
                 <Card className={classes.card}>
                 <CardContent>
                     <Grid container spacing={3}>
                     <Grid
                         item
                         xs={12}
                         sm={12}
                         style={{
                           backgroundColor: "white",
                           display: "flex",
                           justifyContent: "center",
                           alignItems: "center",
                           flexDirection: "row",
                           padding:1
                         }}
                       >
                         <Autocomplete
                         style={{ width:300}}
                         id="free-solo-demo"
                         name="courseId"
                         freeSolo
                         // (event, newValue) => newValue!= null ? setselectedMentor(newValue.id):setselectedMentor(null)
                         onChange={(event, newValue)=>newValue!= null && setState({
                             ...state,
                             courseId:newValue.id
                           })}
                         options={courses}
                         getOptionLabel={option => option.name}
                         renderInput={(params) => (
                         <TextField {...params} label="Select course" margin="normal" variant="outlined" required/>
                             )}
                         />
                       </Grid>
                       <Grid
                         item
                         xs={12}
                         sm={12}
                         style={{
                           backgroundColor: "white",
                           display: "flex",
                           justifyContent: "center",
                           alignItems: "center",
                           flexDirection: "row",
                           padding:1
                         }}
                       >
                          <Autocomplete
                         style={{ width:300}}
                         id="free-solo-demo"
                         freeSolo
                         name="lessonId"
                         onChange={(event, newValue)=>newValue!= null && setState({
                             ...state,
                             lessonId:newValue.id
                           })}
                         options={lessons}
                         getOptionLabel={option => option.name}
                         renderInput={(params) => (
                         <TextField {...params} label="Select lesson" margin="normal" variant="outlined" required/>
                             )}
                         />
                       </Grid>
                      
                     </Grid>
                 </CardContent>
                 <CardActions style={{ justifyContent:'flex-end' }} >
                   <Button type="submit" style={{backgroundColor:color,color:'#fff'}} onClick={handleOnclick} disabled={!(state.lessonId !=null &&state.courseId!=null)}>
                     Go to Tasks
                   </Button>
                 </CardActions>
               </Card>
               </Grid>
               )}/>
        </Switch>
        
    )
}
