import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import {  Grid } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
// import LessonForm from './LessonForm';
import {lessons} from '../../data'
import RegularButton from '../common/Button';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  card: {
    maxWidth: 300,
    minWidth : 200,
    minHeight:100,
    
  },
  cardBg:{
    backgroundColor:'green',
    alignItems:'center'
  }
});
const LessonCardComponent = ({lesson,routePath})=>{
  const classes = useStyles();
  const history =useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const options = [
    {name:'Create Task',routePath:`${routePath}/${lesson.id}/tasks`},
    {name:'View Tasks',routePath:`${routePath}/${lesson.id}/tasks`},
    {name:'Edit Lesson',routePath:`${routePath}/${lesson.id}/edit`},
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return(
    <Card className={classes.card} style={{backgroundColor:lesson.color}}>
       <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' ,padding:0,height:15}}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{padding:1}}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 100,
            width: '12ch',
            marginTop:20
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option}  onClick={()=>history.push(option.routePath)} style={{fontSize:10}}>
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </CardContent>  
      <CardActions>
            <Grid item xs={12} style={{height:232,display:'flex',alignItems:'center',justifyContent:'center'}}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <h6 style={{textAlign:'center',color:'#fff',fontSize:18}}> {lesson.name}</h6>
                </div>
                {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                </div> */}
            </Grid>
      </CardActions>
    </Card>
  )
}

const LessonCard = ({routePath}) =>{
  let { courseId } = useParams();
  console.log({courseId},{routePath})
  const history =useHistory();
  const classes = useStyles();
  return (
    <>
    <div className={classes.root}>
        <Grid xs={12} md={12} sm={12} container spacing={1}>
          {lessons.map(lesson=>(
          <Grid item xs={12} md={3} sm={3} >
                  <LessonCardComponent lesson={lesson} key={lesson.id} routePath={`${routePath}/${courseId}/lessons`}/>
          </Grid>
          ))}
          <RegularButton style={{position: 'fixed', right:30, bottom:100 }} onClick={() => history.push(`${routePath}/${courseId}/add-lesson`)}>New Lesson</RegularButton>
        </Grid>
      </div>
    
    </>
  );
}
export default LessonCard;