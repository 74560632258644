import { Button, Card, IconButton, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@material-ui/core'
import { Edit,Close,Visibility } from '@material-ui/icons';
import React from 'react'
import styles from "../../assets/jss/dashboard-layout/components/tasksStyle";
import { ADD, EDIT } from '../../utils';
import TaskFormModal from './modals/TaskFormModal';

const useStyles = makeStyles(styles);
export default function LessonTasks({color}) {
    const initial ={
        open:false,
        editTask:null,
        action:ADD
    }
    const [state, setState] = React.useState(initial);

  const toggleModal = (obj,actionName) => {
    setState({
        open:true,
        editTask:obj,
        action:actionName
    });
  };
  
  const handleClose = () => {
    setState(initial);
  };

    const classes = useStyles();
    return (
        <>
        <TaskFormModal handleClose={handleClose} state={state}/>
        <Table >
            <TableHead style={{ backgroundColor:color}}>
                <TableCell className={classes.tableCell} align="center" color="#fffff">Tasks</TableCell>
                <TableCell className={classes.tableCell}></TableCell>
                <TableCell className={classes.tableCell}>
                    <Button style={{float:'right',boder:2}} onClick={()=>toggleModal(null,ADD)}>Create new</Button>
                </TableCell>
                </TableHead>
 <TableBody>
     <TableRow style={{backgroundColor:'greenyellow',border:1,marginBottom:2,borderRadius:10,borderColor:'black',borderWidth:1}}>
     <TableCell className={classes.tableCell}>aasd, hkhkadjgjhkh fhhfhfhfhfhfkshdkgh  
          <Typography gutterBottom>
          Average Time require to Learn this Task:
          </Typography>
          <Typography gutterBottom>
          Link to Document:  html, word, other type
          </Typography>
          <Typography gutterBottom>
          Link to Videos to watch (YouTube or other type)
          </Typography>
          <Typography gutterBottom>
          Practice Exercise:  
  Link Chart Group about this Task other 
          </Typography></TableCell>
     <TableCell className={classes.tableCell}></TableCell>
     <TableCell className={classes.tableActions} style={{display:'flex'}} align="right">
     <Tooltip
                id="tooltip-top-start"
                title="Remove"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <IconButton
                  aria-label="Close"
                  className={classes.tableActionButton}
                >
                  <Close
                    className={
                      classes.tableActionButtonIcon + " " + classes.close
                    }
                  />
                </IconButton>
              </Tooltip>
              <Tooltip
                id="tooltip-top"
                title="Edit Task"
                placement="top"
                // classes={{ tooltip: classes.tooltip }}
              >
                <IconButton
                  aria-label="Edit"
                  className={classes.tableActionButton}
                >
                  <Edit
                    className={
                      classes.tableActionButtonIcon + " " + classes.edit
                    }
                    onClick={()=>toggleModal(null,EDIT)}
                  />
                </IconButton>
              </Tooltip>
              
            </TableCell>
     </TableRow>
    
 </TableBody>
        </Table>
        </>
    )
}
