import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import RegularButton from "../../components/common/Button";
import { useHistory } from "react-router-dom";
// import EnrollCell from "./EnrollCell";
import { cells, rows, weeks } from "../../data";
import EnrollStudentCell from "../../components/auth/StudentEnrollCell";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});
let i=1;


const EnrollClassPage = ({ routePath }) => {
  const [enrollCells,setEnrollCells]=useState([]);
  const classes = useStyles();
  const history = useHistory();

  useEffect(()=>{
    console.log({ enrollCells })

  },[enrollCells])
  const changeSelectenrollCells = (status, cellId) => {
    if (status) {
      setEnrollCells([...enrollCells, cellId])
    console.log({ cellId })

    }
    else {
      if (enrollCells.includes(cellId)) {
        let checkedArr = enrollCells
        const index = checkedArr.indexOf(cellId);
        if (index > -1) {
          checkedArr.splice(index, 1);
          setEnrollCells(checkedArr)
          console.log({ enrollCells })
        }
      }
    }
  }
  return (
    <TableContainer component={Paper}>     
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{textAlign:'center'}}>Time</TableCell>
            {weeks.map((week) => (
              <TableCell style={{textAlign:'center'}}>{week.name}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {
            rows.map(row=>(
                <TableRow>
                    <TableCell style={{minWidth:80}}>{row.time}</TableCell>
                {cells.map(cell=> row.id == cell.rowId &&(
                <EnrollStudentCell cell={cell}/>
                ))}
            </TableRow>
            ))
        }
        </TableBody>
        </Table>
         {/* <RegularButton
        style={{ float: "right" }}
        // onClick={() => history.push(routePath) }
      >
        Save changes
      </RegularButton> */}
    </TableContainer>
  );
};
export default EnrollClassPage;

